.contact-us-header {
  font-size: 3rem;
}

.contact-us-body {
  font-size: 20px;
  max-width: 890px;
  margin: auto;
  border: solid;
  border-width: thin;
  border-radius: 15px;
  padding: 65px;
  margin-top: 20px;
}
.contact-us-body-header {
  color: #006400;
  font-size: 2rem;
  font-weight: 600;
  margin: auto;
}

.way-to-contact {
  margin-right: 10px;
  color: black;
  padding: 0px 20px 0px 20px;
}

.way-to-contact:nth-of-type(-n + 1) {
  border-right: solid;
  border-color: #006400;
}

@media only screen and (max-width: 600px) {
  .contact-us-body {
    padding: 20px;
    border: none;
  }

  .way-to-contact {
    padding: 0;
    border: none !important;
    display: block;
    margin-bottom: 10px;
  }
}
