.header {
  border-bottom: solid;
  border-color: #9fd79f;
  border-width: thin;
  border-radius: 20px;
}

.header-text {
  font-size: 40px;
}

.sub-header-text {
  font-weight: 300;
  margin-top: 0;
}
