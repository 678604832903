.nav-text {
  margin-left: 10%;
  text-align: left;
}

.nav-logo {
  margin-left: 20px;
}

.nav-links {
  margin-left: auto;
  margin-right: 75px;
  padding-top: 17px;
}

.nav-link-span {
  font-size: 21px;
  margin-right: 15px;
}

.topnav {
  overflow: hidden;
  display: flex;
  margin-bottom: 20px;
  margin-top: 20px;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  margin: 10px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  color: #006400;
  text-decoration: solid;
}

/* Add an active class to highlight the current page */
.topnav a.active {
  background-color: #ddd;
  color: black;
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
  display: none;
}

@media only screen and (max-width: 600px) {
  .topnav {
    margin: 0;
    margin-bottom: 15px;
    flex-direction: column;
  }

  .nav-links {
    margin-bottom: 5px;
    align-self: center;
    margin: 0;
  }

  .nav-logo {
    align-self: center;
    margin: 0;
  }
}
